import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    face: {
      main: '#0BBDD1',
      contrast: '#723995',
      secondary: '#F5F5F5',
      light: '#FFFFFF',
      dark: '#595A59',
      border: '#707070',
      lightBorder: '#DCDCDC',
    },

    gradient: {
      main: 'linear-gradient(90deg, rgba(114,57,149,1) 0%, rgba(11,189,209,1) 100%)',
      secondary: 'linear-gradient(120deg, rgba(114,57,149,1) 0%, rgba(11,189,209,1) 100%)',
      third: 'linear-gradient(180deg, rgba(114,57,149,1) 0%, rgba(11,189,209,1) 100%)',
      fourth: 'linear-gradient(270deg, rgba(114,57,149,1) 0%, rgba(11,189,209,1) 100%)'
    },

    text: {
      main: '#2A2A2A',
      dark: '#595A59',
      contrast: '#0BBDD1',
      secondary: '#723995',
      light: '#FFFFFF',
      soft: '#9A9A9A',
      positive: '#3DBC4A',
      negative: '#F2665B',
    },
  },

  font: {
    family: {
      main: 'ainslie-sans, sans-serif',
      secondary: 'ainslie-sans, sans-serif',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '15px',
      ms: '16px',
      mx: '18px',
      xm: '20px',
      l: '24px',
      lx: '30px',
      xl: '32px',
      xml: '36px',
      xxl: '45px',
      xxxl: '70px',
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 600,
      xl: 800,
    }
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${props => props.theme.font.family.main};
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.text.main};
  }

  ${props => Object.keys(props.theme.color.face).map(key => {
    return `.color-background-${key} { background-color: ${props.theme.color.face[key]}; }`
  })}

  ${props => Object.keys(props.theme.color.text).map(key => {
    return `.color-${key} { color: ${props.theme.color.text[key]}; }`
  })}

  ${props => Object.keys(props.theme.font.family).map(key => {
    return `.font-family-${key} { font-family: ${props.theme.font.family[key]}; }`
  })}

  ${props => Object.keys(props.theme.font.size).map(key => {
    return `.font-size-${key} { font-size: ${props.theme.font.size[key]} !important; }`
  })}

  ${props => Object.keys(props.theme.font.weight).map(key => {
    return `.font-weight-${key} { font-weight: ${props.theme.font.weight[key]}; }`
  })}

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
`

const Theme = ({ children }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default Theme