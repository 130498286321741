/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import CustomLink from 'components/shared/CustomLink'

const ButtonShell = ({
  external,
  newPage,
  isAnchor,
  to,
  children,
  className,
  target,
}) =>
  !isAnchor ? (
    <CustomLink
      external={external}
      newPage={newPage}
      to={to}
      className={className}
      targe={target}
    >
      {children}
    </CustomLink>
  ) : (
    <a href={to} className={className} target={external ? '_blank' : undefined}>
      {children}
    </a>
  )

export default ButtonShell
