/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

// Components
import ButtonShell from 'components/elements/ButtonShell'

// Images
import ArrowButton from 'img/icon/arrow_button.inline.svg'
// import UserButton from 'img/icon/user_button.inline.svg'
import userButton from 'img/icon/user_button.svg'

const StyledButton = styled(motion.div)`
  position: relative;
  background: ${(props) => props.theme.color.gradient.main};
  border-radius: 29.5px;
  display: inline-block;

  ${(props) =>
    props.small
      ? css`
          height: 30px;
        `
      : css`
          height: 45px;
        `}

  & > a, & > button {
    color: ${(props) => props.theme.color.text.light} !important;
    font-weight: ${(props) => props.theme.font.weight.xl};

    ${(props) =>
      props.small
        ? css`
            padding: 5px 20px 5px 35px;
          `
        : css`
            padding: 10px 50px;
          `}

    & [disabled] {
      opacity: 0.5;

      &:hover {
        text-decoration: none !important;
      }
    }

    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
    white-space: nowrap;

    &:hover {
      text-decoration: underline !important;
      color: ${(props) => props.theme.color.text.light};
    }
  }
`

const IconWrapper = styled(motion.div)`
  position: absolute;
  pointer-events: none;

  ${(props) =>
    props.small
      ? css`
          top: 2px;
          height: 19px;
          width: 19px;
        `
      : css`
          top: 7px;
          height: 31px;
          width: 31px;
        `}

  img, svg {
    height: 100%;
    width: 100%;

    path {
      fill: ${(props) => props.theme.color.text.light};
      stroke: ${(props) => props.theme.color.text.light};
    }
  }
`

const ButtonDefault = ({
  external,
  newPage,
  small,
  icon,
  isAnchor,
  isCustom,
  to,
  children,
  className,
  target,
}) => (
  <StyledButton
    initial="rest"
    whileHover="hover"
    animate="rest"
    className={className}
    small={small}
  >
    {
      {
        arrow: (
          <IconWrapper
            small={small}
            variants={{
              rest: { left: '8px' },
              hover: {
                left: 'calc(100% - 39px)',
                transition: { duration: 0.3 },
              },
            }}
          >
            <ArrowButton />
          </IconWrapper>
        ),
        user: (
          <IconWrapper
            small={small}
            variants={{
              rest: { left: '8px' },
              hover: {
                rotate: ['0deg', '20deg', '-20deg', '0deg'],
                transition: { duration: 0.3 },
              },
            }}
          >
            <img src={userButton} alt="" />
          </IconWrapper>
        ),
      }[icon]
    }
    {isCustom ? (
      children
    ) : (
      <ButtonShell
        external={external}
        newPage={newPage}
        to={to}
        isAnchor={isAnchor}
        target={target}
      >
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault
