/* eslint-disable global-require */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// import { Link } from 'gatsby'

// Libraries
// import CookieConsent from 'react-cookie-consent'
// import CookieBot from 'react-cookiebot'

// Theme
import Theme from 'styles/Theme'

// Components
import Footer from './Footer'
import Header from './Header'

// CSS
import 'styles/all.scss'

const Content = styled.div`
  @media (min-width: 992px) {
    padding-top: ${(props) => (props.showActionBanner === 't' ? 135 : 100)}px;
  }

  @media (max-width: 991px) {
    padding-top: ${(props) => (props.showActionBanner === 't' ? 85 : 50)}px;
  }
`

// const CookieLink = styled(Link)`
//   color: ${props => props.theme.color.text.light};
//   text-decoration: underline;
// `

const Layout = ({ children, spacingBottom = false }) => {
  const [isSticky, setSticky] = useState(false)
  const [showActionBanner, setShowActionBanner] = useState('t')

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    const showActionBannerLocal = window.localStorage.getItem('action-banner')

    if (showActionBannerLocal) {
      setShowActionBanner(showActionBannerLocal)
    }

    handleScroll()

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  const setShowActionBannerLocal = (value) => {
    window.localStorage.setItem('action-banner', value)

    setShowActionBanner(value)
  }

  return (
    <Theme>
      <Header
        isSticky={isSticky}
        setShowActionBanner={setShowActionBannerLocal}
        showActionBanner={showActionBanner}
      />
      <Content isSticky={isSticky} showActionBanner={showActionBanner}>
        {children}
      </Content>
      <Footer spacingBottom={spacingBottom} />

      {/* <CookieBot domainGroupId="493e4867-1b86-4638-867d-4c0c13c121ed" /> */}

      {/* <CookieConsent
        location="bottom"
        style={{ zIndex: '99999999999', background: 'linear-gradient(90deg, rgba(114,57,149,1) 0%, rgba(11,189,209,1) 100%)', color: '#FFFFFF' }}
        buttonText="Akkoord"
      >
        <div className="px-lg-5 font-size-sm">
          <div>
            We gebruiken cookies om ervoor te zorgen dat onze website zo soepel mogelijk draait. Als u doorgaat met het gebruiken van de website, gaan we er vanuit dat u ermee instemt.
            {' '}
            <CookieLink to="/privacyverklaring">
              Lees hier de
              {' '}
              privacyverklaring
            </CookieLink>
          </div>
        </div>
      </CookieConsent> */}
    </Theme>
  )
}

export default Layout
